import { useEffect } from 'react';

const IndexPage = () => {
    // just navigate to scoreshuttle.com when they try to access the root of the gatsby app
    useEffect(() => {
        window.location.href = 'https://www.scoreshuttle.com';
    }, []);
    return null;
};

export default IndexPage;
